import HorizontalProducts from "components/layout/store/products/HorizontalProducts";
import GridProducts from "components/layout/store/products/GridProducts";
import {isProductSoldOut, shuffle} from "common/utils";
import styles from "components/sections/curation/CurationSection.module.scss";
import {useMemo} from "react";


export const ProductCurationSection = (props) => {
    const {curation, curationIdx, onPouchAdd, location, isAddToPouchVisible, useBestCouponDiscount, theme} = props;

    const products = useMemo(() => {
        let items = curation.item_list.filter((product) => {
            return !isProductSoldOut(product)
        });
        if (curation.max_item_count) {
            items = items.slice(0, curation.max_item_count);
        }
        return items;
    }, [curation]);

    const handleProductClick = props.onProductClick || (
        (product, productIndex) => {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'ProductCurationSectionProductClick',
                {
                    location,
                    curationTitle: curation.title,
                    curationDescription: curation.description,
                    productId: product.id,
                    productName: product.name,
                    productIndex,
                    curationIdx
                }
            );
        }
    );

    if (curation.display_type === 'HORIZONTAL') {
        if (curation.is_rank_shown) {
            for (let i = 0; i < products.length; i++) {
                products[i].rank = i + 1;
            }
        }
        return (
            <HorizontalProducts
                onPouchAdd={onPouchAdd}
                products={products}
                onProductClick={handleProductClick}
                isAddToPouchVisible={isAddToPouchVisible}
                showRank={curation.is_rank_shown}
                useBestCouponDiscount={useBestCouponDiscount}
                theme={theme}
            />
        )
    } else if (curation.display_type.includes('COLUMN')) {
        const column_count_str = curation.display_type.split('_')[0];
        const column_count = column_count_str === 'ONE' ? 1 : column_count_str === 'TWO' ? 2 : 3;
        return (
            <div className={styles.gridContainer}>
                <GridProducts
                    onPouchAdd={onPouchAdd}
                    products={products}
                    onProductClick={handleProductClick}
                    columnCount={column_count}
                    isAddToPouchVisible={isAddToPouchVisible}
                    showPromotionDaysLeft
                    useBestCouponDiscount={useBestCouponDiscount}
                    theme={theme}
                />
            </div>
        )
    } else {
        return <div/>;
    }
}
