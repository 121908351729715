import styles from 'components/sections/mainpage/MainPageSectionList.module.scss';
import {CurationSection} from "components/sections/curation/CurationSection";
import {Axios, CDNAxios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";
import {useEffect, useRef, useState} from "react";
import {useRouter} from "next/router";
import Loading from "components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import {postScrapContextRef} from "context/PostScrapContext";
import {productScrapContextRef} from "context/ProductScrapContext";
import {isProductSoldOut, shuffle} from "common/utils";

const PAGE_SIZE = 4;

export const shuffleCurationItems = (curation) => {
    if (!(curation?.item_list?.length > 0)) {
        return;
    }

    let items = curation.item_list;

    if (curation.category === 'product') {
        items = curation.item_list.filter((product) => {
            return !isProductSoldOut(product)
        });
    }

    if (curation.category === 'product' || curation.category === 'post') {
        if (curation.is_shuffled)  {
            shuffle(items);
        }

        if (curation?.max_item_count > 0) {
            items = items.slice(0, curation.max_item_count);
        }

        curation.item_list = items;
    } else if (curation.category === 'bullets') {
        for (let item of curation.item_list) {
            shuffleCurationItems(item);
        }
    }
};

export const MainPageSectionList = () => {
    const router = useRouter();

    const cursorRef = useRef(null);
    const defaultCurationData = {
        curations: [],
    };

    const [isLoading, setIsLoading] = useState(true);
    const [mainPageCurationData, setMainPageCurationData] = useState(defaultCurationData);
    const [error, setError] = useState(false);

    const fetchCurations = async () => {
        setIsLoading(true);

        try {
            const res = await CDNAxios.get('v1/curation/mainpage/children', {
                params: {
                    cursor: cursorRef.current,
                    size: PAGE_SIZE,
                },
            });

            if (res.status < 400) {
                cursorRef.current = res.data.cursor;
                setMainPageCurationData(oldVal => {
                    res.data.curations.forEach(arg => {
                        shuffleCurationItems(arg);
                    });

                    const newVal = JSON.parse(JSON.stringify(oldVal));

                    return {
                        curations: [...newVal.curations, ...res.data.curations],
                    };
                });
            } else {
                captureMessage(JSON.stringify(res.data));
                setError(true);
            }
        } catch (e) {
            setError(true);
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchScraps = async (curations) => {
        try {
            const productIds = [], postIds = [];

            for (const curation of curations) {
                const info = extractIdInfoFromCuration(curation);
                productIds.push(...info.productIds);
                postIds.push(...info.postIds);
            }

            const data = {
                product_ids: productIds,
                post_ids: postIds,
            };

            const res = await Axios.post('/v1/scraps/', data);

            if (res.status < 400) {
                const data = res.data;
                postScrapContextRef.current.addPostIds(data.scrapped_post_ids);
                productScrapContextRef.current.addProductIds(data.scrapped_product_ids);
            }
        } catch (e)  {
            captureException(e);
        }
    };

    useEffect(() => {
        fetchScraps(mainPageCurationData.curations);
    }, [mainPageCurationData.curations]);

    const extractIdInfoFromCuration = (curation) => {
        const productIds = [];
        const postIds = [];

        if (curation.category === 'product') {
            const new_products = curation.item_list.map(item => item.id);
            productIds.push(...new_products);
        } else if(curation.category === 'post') {
            const new_posts = curation.item_list.map(item => item.id);
            postIds.push(...new_posts);
        } else if(curation.category === 'bullets') {
            for (let item of curation.item_list) {
                const info = extractIdInfoFromCuration(item);
                postIds.push(...info.postIds);
                productIds.push(...info.productIds);
            }
        }

        return {
            'productIds': productIds,
            'postIds' : postIds,
        };
    }

    useEffect(() => {
        fetchCurations();
    }, []);

    return (
        <div className={styles.root}>
            {
                isLoading && mainPageCurationData.curations.length === 0 ?
                    <Loading style={{marginTop: 200, marginBottom: 150}}/>
                    :
                    <InfiniteScroll
                        scrollThreshold={'80%'}
                        hasMore={!!cursorRef.current}
                        dataLength={mainPageCurationData.curations.length}
                        next={fetchCurations}
                        endMessage={undefined}
                        loader={
                            <div>
                                <Loading style={{marginTop: 20, marginBottom: 20}}/>
                            </div>
                        }
                        style={{overflow: 'hidden'}}
                        className={styles.listContainer}
                    >
                        {
                            mainPageCurationData.curations.map((curation, curationIdx) => {
                                return <CurationSection
                                    key={`curation-${curation.id}-${curationIdx}`}
                                    curation={curation}
                                    curationIdx={curationIdx}
                                    location="mainpage"
                                    useBestCouponDiscount={true}
                                />
                            })
                        }
                    </InfiniteScroll>
            }
        </div>
    )
}
